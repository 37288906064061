import {useEffect, useState} from 'react'
import {classicLogin, getUserByToken, googleLogin} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_dsm/helpers'
import {useAuth} from '../core/Auth'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useNavigate} from 'react-router-dom';

// Inside your component


const FRONT_URL = process.env.REACT_APP_PUBLIC_URL
const parseQuery = (query) => {
    return query
        .substring(1) // Remove the leading '?'
        .split('&') // Split by '&'
        .map(kv => kv.split('=')) // Split key/values
        .reduce((acc, [key, value]) => ({...acc, [key]: decodeURIComponent(value)}), {}); // Build response object
};

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Veuillez renseigner une adresse email valide')
        .required('Veuillez indiquer une adresse e-mail'),
    password: Yup.string()
        .required('Veuillez indiquer un mot de passe'),
})

const initialValues = {
    email: '',
    password: '',
}

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export function Login() {
    const navigate = useNavigate();
    const {saveAuth, setCurrentUser} = useAuth()
    const [loading, setLoading] = useState(false)
    const state = Math.random().toString(36).substring(7);
    const [checkResponse, setCheckResponse] = useState(true)
    const oauthSignIn = () => {
        var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
        var form = document.createElement('form');
        form.setAttribute('method', 'GET');
        form.setAttribute('action', oauth2Endpoint);
        var params = {
            'client_id': GOOGLE_API_KEY,
            'redirect_uri': FRONT_URL + '/auth',
            'response_type': 'code',
            'scope': 'openid email profile',
            'state': state
        };
        window.sessionStorage.setItem('oauth2.state', state);
        for (var p in params) {
            var input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', p);
            input.setAttribute('value', params[p]);
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
    }

    const handleLogin = async () => {
        oauthSignIn();
    }

    const handleCredentialResponse = async (response) => {
        try {
            const storedState = window.sessionStorage.getItem('oauth2.state');
            if (response.state === storedState) {
                googleLogin(response.code)
                    .then(response => {
                        const auth = response.data
                        saveAuth({api_token: auth.fm_at, refreshToken: auth.fm_rt})
                        getUserByToken(auth.fm_at)
                            .then(user_response => {
                                const user = user_response.data
                                setCurrentUser(user)
                            })
                            .catch(error => {
                                console.log(error)
                                navigate('/auth')
                            })
                    }).catch(error => {
                    console.log(error)
                    navigate('/auth')
                })

            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        console.log(window.location.search)
        if (window.location.search) {
            const response = parseQuery(window.location.search);
            handleCredentialResponse(response).catch(err => console.log(err))
        } else {
            setCheckResponse(false)
        }
    });

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                const {data: auth} = await classicLogin(values.email, values.password)
                saveAuth({api_token: auth.fm_at, refreshToken: auth.fm_rt})
                const {data: user} = await getUserByToken(auth.fm_at)
                console.log(user)
                setCurrentUser(user)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('Identifiants de connexion incorrects')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <>
            {checkResponse ? (
                <div className={"position-fixed top-0 start-0 bottom-0 end-0 bg-white  vw-100 vh-100 z-50"}>
                    <div className={"d-flex justify-content-center align-items-center h-100"}>
                        <div className={"spinner-border"} role={"status"}>
                            <span className={"visually-hidden"}>Loading...</span>
                        </div>
                    </div>
                </div>
            ) : (
                <>

                    <div>
                        <div className='text-center mb-11'>
                            <h1 className='text-dark fw-bolder mb-3'>BIENVENUE !</h1>
                        </div>
                        <div className='row g-3 mb-9'>
                            {/* begin::Col */}
                            <div className='col-md-12 d-flex justify-content-center'>
                                {/* begin::Google link */}
                                <a
                                    onClick={() => handleLogin()}
                                    href={'#login'}
                                    className='btn btn-flex btn-outline btn-text-dark btn-active-color-primary bg-state-light flex-center text-nowrap w-100 border-1 border-dark'
                                >
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                                        className='h-15px me-3'
                                    />
                                    CONNEXION AVEC GOOGLE
                                </a>
                            </div>
                        </div>
                        <div className='separator separator-content my-14'>
                            <span className='w-125px text-gray-500 fw-semibold fs-7'>Ou</span>
                        </div>
                        {/* end::Separator */}
                        <form
                            className='form w-100'
                            onSubmit={formik.handleSubmit}
                            noValidate
                            id='kt_login_signin_form'
                        >
                            {/* begin::Form group */}
                            <div className='fv-row mb-8'>
                                <label className='form-label fs-6 fw-bolder text-dark'>E-MAIL</label>
                                <input
                                    placeholder='Email'
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control bg-transparent border-bottom-1 border-black rounded-0 border-top-0 border-end-0 border-start-0 shadow-none',
                                        {'is-invalid': formik.touched.email && formik.errors.email},
                                    )}
                                    type='email'
                                    name='email'
                                    autoComplete='off'
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <span className='text-danger'>{formik.errors.email}</span>
                                    </div>
                                )} {formik.status && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span className='text-danger'>{formik.status}</span>
                                    </div>
                                </div>
                            )}

                            </div>
                            <div className='fv-row mb-3'>
                                <label className='form-label fw-bolder text-dark fs-6 mb-0'>MOT DE PASSE</label>
                                <input
                                    type='password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent border-bottom-1 border-black rounded-0 border-top-0 border-end-0 border-start-0 shadow-none',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                    )}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span className='text-danger'>{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                                <div/>

                                <Link to='/auth/forgot-password' className='link-primary'>
                                    Mot de passe oublié?
                                </Link>
                            </div>
                            <div className='d-grid mb-10'>
                                <button
                                    type='submit'
                                    id='kt_sign_in_submit'
                                    className='btn btn-primary'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='indicator-label'>Connexion</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                      Please wait...
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </>
    )
}
