/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../app/modules/auth'
import {useIntl} from 'react-intl'

const SidebarFooter = () => {
    const {logout} = useAuth()
    const intl = useIntl()
    return (
        <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
            <a
                onClick={logout}
                href={'#logout'}
                className='text-white text-nowrap px-0 h-40px w-100 cursor-pointer'
                title='Déconnexion'
            >
                <i className="fa-solid fa-power-off text-white pe-2"></i>
                <span className='btn-label'>{intl.formatMessage({id: 'AUTH.LOGOUT.TITLE'})}</span>
            </a>
        </div>
    )
}

export {SidebarFooter}
