/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
    const intl = useIntl()
    const { currentUser} = useAuth()
    const [withLink, setWithLink] = React.useState(true);

    useEffect(() => {
        if (currentUser && currentUser.roles && currentUser.roles.includes('ROLE_EXTERNAL')) {
            setWithLink(false);
        } else {
            setWithLink(true);
        }
    }, [currentUser]);
    return (
        <>
            <SidebarMenuItem
                to='/weproov-reports'
                icon='some-files'
                title={intl.formatMessage({id: 'MENU.WPREPORT'})}
                fontIcon='bi-app-indicator'
            />
            {withLink && (
                <>
                    <SidebarMenuItem
                        to='/trailers'
                        icon='truck'
                        title={intl.formatMessage({id: 'MENU.REGISTRATION'})}
                        fontIcon='bi-app-indicator'
                    />
                    <SidebarMenuItem
                        to='/hauliers'
                        icon='data'
                        title={intl.formatMessage({id: 'MENU.HAULIER'})}
                        fontIcon='bi-app-indicator'
                    />
                </>

            )}

        </>
    )
}


export {SidebarMenuMain}
