import {AuthModel} from './_models'
import {refreshToken} from './_requests'
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import { useNavigate } from 'react-router-dom';

import jwt_decode from "jwt-decode";

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

const getRoleFromToken = (token) => {
    try {
        const decoded = jwt_decode(token);
        // @ts-ignore
        return decoded ? decoded.roles : null;

    } catch (error) {
        console.error('Failed to decode JWT', error);
        return null;
    }
};

const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function setupAxios(axiosInstance: any) {
    axiosInstance.defaults.headers.Accept = 'application/json';

    axiosInstance.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            const auth = getAuth();
            if (auth && auth.api_token) {
                config.headers.Authorization = `Bearer ${auth.api_token}`;
            }

            return config;
        },
        (err: any) => Promise.reject(err)
    );

    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        async (error) => {
            console.log("I INTERCEPT THE ERROR")
            const originalRequest: CustomAxiosRequestConfig = error.config;
            if ((error.response === undefined || error.response.status === 401) && !originalRequest._retry) {
                originalRequest._retry = true;
                const auth = getAuth();
                if (!auth?.refreshToken){
                    removeAuth();
                    console.log("I'M HERE 1")
                    window.location.href = '/auth';
                }
                else {
                    const refreshResponse = await refreshToken(auth?.refreshToken);
                    if (refreshResponse && refreshResponse.fm_at && refreshResponse.fm_rt) {
                        setAuth({api_token: refreshResponse.fm_at, refreshToken: refreshResponse.fm_rt})
                        // And then retry the original request.
                        originalRequest.headers = originalRequest.headers || {};
                        originalRequest.headers.Authorization = `Bearer ${refreshResponse.fm_at}`;
                        return axiosInstance(originalRequest);
                    }
                }
                removeAuth();
                console.log("I'M HERE 2")
                window.location.href = '/auth';
            }
            return Promise.reject(error);
        }
    );
}


export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
