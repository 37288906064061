import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {setPassword} from '../core/_requests'

const initialValues = {
    password: '',
    changepassword: '',
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const setPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, '8 caractères minimum')
        .matches(/[A-Z]/, '1 majuscule')
        .matches(/[a-z]/, '1 minuscule')
        .matches(/[0-9]/, '1 chiffre')
        .required('Veuillez indiquer un mot de passe'),
    changepassword: Yup.string()
        .required('Veuillez confirmer votre mot de passe')
        .oneOf([Yup.ref('password')], "Les mots de passe ne correspondent pas"),
});


export function SetPassword() {
    const query = useQuery();
    const token = query.get('token') || '';
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isFinished, setIsFinished] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: setPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(() => {
                setPassword(values.password, token)
                    .then(() => {
                        setHasErrors(false)
                        setLoading(false)
                        setIsFinished(true)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('The login detail is incorrect')
                    })
            }, 1000)
        },
    })
    const passwordValue = formik.values.password;
    const isMinLengthMet = passwordValue.length >= 8;
    const hasUppercase = /[A-Z]/.test(passwordValue);
    const hasLowercase = /[a-z]/.test(passwordValue);
    const hasDigit = /[0-9]/.test(passwordValue);

    return (
        isFinished ? (
            <>
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark fw-bolder mb-10'>BIENVENUE</h1>
                </div>
                <div className='text-dark text-start text-center fw-semibold fs-6 mt-10'>
                    Votre mot de passe est enregistré, vous pouvez vous connecter.
                </div>
                <div className='d-flex flex-wrap justify-content-center pb-lg-0 mb-3 mt-20'>
                    <Link to={'/auth/login'}>
                        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary w-100'>
                            <span className='indicator-label text-capitalize'>RETOUR À LA CONNEXION</span>
                        </button>
                    </Link>
                </div>
            </>
        ) : (
            <>
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='text-center mb-10'>
                        {/* begin::Title */}
                        <h1 className='text-dark fw-bolder mb-10'>BIENVENUE</h1>
                        {/* end::Title */}

                        {/* begin::Link */}
                        <div className='text-dark text-start fw-semibold fs-6'>
                            Afin de pouvoir vous connecter, veuillez choisir un mot de passe.
                        </div>
                        {/* end::Link */}
                    </div>

                    {/* begin::Title */}
                    {hasErrors === true && (
                        <div className='mb-5 text-danger'>
                            <div className='alert-text font-weight-bold'>
                                Votre demande de réinitialisation de mot de passe a expirée. Veuillez recommencer la
                                procédure.
                            </div>
                        </div>
                    )}
                    {/* end::Title */}

                    {/* begin::Form group Password */}
                    <div className='fv-row mb-8' data-kt-password-meter='true'>
                        <div className='mb-1'>
                            <label className='form-label fw-bolder text-dark fs-6'>MOT DE PASSE</label>
                            <div className='position-relative mb-3'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Mot de passe'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        {'is-invalid': formik.touched.password && formik.errors.password},

                                        'form-control bg-transparent border-bottom-1 border-black rounded-0 border-top-0 border-end-0 border-start-0 shadow-none',
                                    )}
                                />
                                <i
                                    className={clsx("fa", showPassword ? "fa-eye-slash" : "fa-eye", "position-absolute mb-2 me-3")}
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{ cursor: "pointer", right: "30px", bottom: "8px"}}
                                ></i>
                            </div>
                            {/* begin::Meter */}
                            <div
                                className='text-start text-gray-700 mb-3 flex-column'
                                data-kt-password-meter-control='highlight'
                            >
                                <div className='text-start text-gray-700 mb-3 flex-column' data-kt-password-meter-control='highlight'>
                                    <div style={{color: isMinLengthMet ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}>
                                        <i
                                            style={{color: isMinLengthMet ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}
                                            className={`fa fs-5 ${isMinLengthMet ? 'fa-check text-green' : (formik.submitCount > 0 ? 'fa-times text-red' : '')}`}>
                                        </i> 8 caractères minimum
                                    </div>
                                    <div style={{color: hasUppercase ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}>
                                        <i
                                            style={{color: hasUppercase ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}
                                            className={`fa fs-5 ${hasUppercase ? 'fa-check text-green' : (formik.submitCount > 0 ? 'fa-times text-red' : '')}`}>
                                        </i> 1 majuscule
                                    </div>
                                    <div style={{color: hasLowercase ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}>
                                        <i
                                            style={{color: hasLowercase ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}
                                            className={`fa fs-5 ${hasLowercase ? 'fa-check text-green ' : (formik.submitCount > 0 ? 'fa-times text-red' : '')}`}>

                                        </i> 1 minuscule
                                    </div>
                                    <div style={{color: hasDigit ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}>
                                        <i
                                            style={{color: hasDigit ? 'green' : (formik.submitCount > 0 ? 'red' : 'inherit')}}
                                           className={`fa fs-5 ${hasDigit ? 'fa-check text-green' : (formik.submitCount > 0 ? 'fa-times text-red' : '')}`}>
                                        </i> 1 chiffre
                                    </div>
                                </div>
                            </div>
                            {/* end::Meter */}
                        </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Confirm password */}
                    <div className='fv-row mb-5'>
                        <label className='form-label fw-bolder text-dark fs-6'>CONFIRMATION DU MOT DE PASSE</label>
                        <div className='position-relative mb-3'>
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder='Confirmation du mot de passe'
                            autoComplete='off'
                            {...formik.getFieldProps('changepassword')}
                            className={clsx(
                                {'is-invalid': formik.touched.changepassword && formik.errors.changepassword},

                                'form-control bg-transparent border-bottom-1 border-black rounded-0 border-top-0 border-end-0 border-start-0 shadow-none',
                            )}
                        />
                        <i
                            className={clsx("fa", showConfirmPassword ? "fa-eye-slash" : "fa-eye", "position-absolute  mb-2 me-3")}
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            style={{ cursor: "pointer", right: "30px", bottom: "8px"}}
                        ></i>
                        </div>
                        {formik.touched.changepassword && formik.errors.changepassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.changepassword}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='d-flex flex-wrap justify-content-center pb-lg-0 mb-3 mt-20'>
                        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary w-100'>
                                <span
                                    className='indicator-label text-capitalize'>ENREGISTRER</span>
                            {loading && (
                                <span className='indicator-progress'>
                                    Veuillez attendre...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* end::Form group */}
                </form>
            </>
        )
    )
}
