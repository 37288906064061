import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
      .email('Veuillez saisir une adresse e-mail valide')
      .required('Veuillez indiquer une adresse e-mail'),
})

export function ForgotPassword() {
  const [isFinished, setIsFinished] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setIsFinished(true)
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      }, 1000)
    },
  })

  return (
      isFinished ? (
          <>
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark fw-bolder mb-10'>MOT DE PASSE OUBLIÉ !</h1>
            </div>
            <div className='text-dark text-start fw-semibold fs-6 mt-10'>
              Nous avons envoyé un lien de réinitialisation du mot de passe à l’adresse :
            </div>
            <div className='text-dark text-start  fw-bold fs-6 mt-10'>
              {formik.values.email}
            </div>
            <div className='d-flex flex-wrap justify-content-center pb-lg-0 mb-3 mt-20'>
              <Link to={'/auth/login'}>
                <button type='submit' id='kt_password_reset_submit' className='btn btn-primary w-100'>
                  <span className='indicator-label text-capitalize'>RETOUR À LA CONNEXION</span>
                </button>
              </Link>
            </div>
          </>
      ):(
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-10'>MOT DE PASSE OUBLIÉ ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-dark text-start fw-semibold fs-6'>
          Saisissez l’adresse email associée à votre compte. Nous vous enverrons un lien par email pour réinitialiser votre mot de passe.
        </div>
        {/* end::Link */}
      </div>
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>E-MAIL</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
              'form-control bg-transparent border-bottom-1 border-black rounded-0 border-top-0 border-end-0 border-start-0 shadow-none',
              {'is-invalid': formik.touched.email && formik.errors.email},
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert text-danger'>{formik.errors.email}</span>
            </div>
          </div>
        )}
        {hasErrors && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert text-danger'>Nous ne trouvons aucun compte avec cette adresse e-mail
                </span>
              </div>
            </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0 mb-3'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary w-100'>
          <span className='indicator-label text-uppercase'>réinitialiser mon mot de passe</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <Link to='/auth/login'>
          <a
            type='button'
            id='kt_login_password_reset_form_cancel_button'>
            Revenir à la connexion
          </a>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
    )
  )
}
