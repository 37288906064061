import axios from 'axios'
import {UserModel, ApiAuthModel} from './_models'
import * as authHelper from "./AuthHelpers";
import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL
const FRONT_URL = process.env.REACT_APP_PUBLIC_URL as string

export const GET_GOOGLE_AUTH_URL = `${API_URL}/connect/google`
export const LOGIN_URL = `${API_URL}/auth/login`

export const SET_PASSWORD_URL = `${API_URL}/password/create/:token`

export const REQUEST_PASSWORD_URL = `${API_URL}/password/reset`

export const GOOGLE_LOGIN_URL = `${API_URL}/social/google/tokens?code=:code&redirectUri=:redirect_uri`
export const REGISTER_URL = `${API_URL}/register`
export const REFRESH_TOKEN = `${API_URL}/tokens/refresh?refreshToken=:refreshToken`

const getRoleFromToken = (token) => {
    try {
        const decoded = jwt_decode(token);
        console.log(decoded)
        // @ts-ignore
        return decoded ? decoded.roles : null;

    } catch (error) {
        console.error('Failed to decode JWT', error);
        return null;
    }
};

export function getGoogleAuthRoute() {
    return axios.get(GET_GOOGLE_AUTH_URL)
}

export const refreshToken = async (refreshToken): Promise<ApiAuthModel | null> => {
    try {
        const response = await axios.post(REFRESH_TOKEN.replace(':refreshToken', refreshToken));
        return response.data;
    } catch (error) {
        return null;
    }
};

export function googleLogin(googleCode:string) {
  return axios.get<ApiAuthModel>(
      GOOGLE_LOGIN_URL.replace(':code', googleCode)
          .replace(':redirect_uri', FRONT_URL+'/auth')
  )
}

export function classicLogin(email:string, password:string) {
    return axios.post<ApiAuthModel>(
        LOGIN_URL, {email:email, password:password}
    )
}

export function setPassword(password:string,token:string) {
    return axios.post<ApiAuthModel>(
        SET_PASSWORD_URL.replace(':token', token), {plainPassword:password}
    )
}

export function requestPassword(email: string) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token: string) {
    return new Promise<{data: UserModel}>((resolve, reject) => {
        // In a real app, you would validate the token here
        if (token) {
            resolve({
                data: {
                    username: 'administrateur',
                    roles: getRoleFromToken(token)
                    // add more fields as necessary...
                }
            });
        } else {
            reject('Invalid token');
        }
    });
}
