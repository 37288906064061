export type ID = undefined | null | number

export type PaginationState = {
  page: number
  itemsPerPage: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: object
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  itemsPerPage: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type listResponse<T> = {
    data: Array<T>,
    total: number,
}

export type QueryResponseContextProps<T> = {
  response?: {
    data: Array<T>,
    count: {total: number},
  } | undefined
  refetch: () => void
  isLoading: boolean
  query: string
  newQuery: boolean
  setNewQuery: Function
  page: number
  setPage: Function
  isAdditionalLoading: boolean
  setIsAdditionalLoading: Function
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: '', newQuery: false, setNewQuery:()=>{}, page: 1, setPage:()=>{}, isAdditionalLoading: false, setIsAdditionalLoading: ()=>{}}

export type ListViewContextProps = {
  vueType : string,
  setVueType : Function,
}

export const initialListView: ListViewContextProps = {
  vueType : "volume",
  setVueType : () => {},
}
