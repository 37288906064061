import { lazy, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_dsm/layout/MasterLayout'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

const PrivateRoutes = () => {
  const HauliersPage = lazy(() => import('../pages/haulier/HauliersPage'))
  const TrailersPage = lazy(() => import('../pages/trailer/TrailersPage'))
  const ReportsPage = lazy(() => import('../pages/weproov-reports/ReportsPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/weproov-reports/list' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='hauliers/*' element={<Suspense><HauliersPage /></Suspense>} />
        <Route path='trailers/*' element={<Suspense><TrailersPage /></Suspense>} />
        <Route path='weproov-reports/*' element={<Suspense><ReportsPage /></Suspense>} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
export { PrivateRoutes }
