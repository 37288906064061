import {Route, Routes} from 'react-router-dom'
import {Login} from './components/Login'
import {ForgotPassword} from './components/ForgotPassword'
import {SetPassword} from './components/SetPassword'
import {AuthLayout} from './AuthLayout'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='create-password' element={<SetPassword />} />
        <Route path='reset-password' element={<SetPassword />} />
        <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
